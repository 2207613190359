import Document from './Document';
import Hero from './slices/Hero';
import ProductGroups from './slices/ProductGroups';
import Products from './slices/Products';
import Slice from './slices/Slice';

class FrontPage extends Document {
  constructor(frontPage, siteUID, products) {
    super(frontPage, siteUID);

    this.superHeros = frontPage.superHeros.map(
      (superHero) => new Hero(superHero, siteUID)
    );

    if (frontPage.productGroups[0]) {
      this.productGroupsSlice = FrontPage.parseSlice(
        frontPage.productGroups[0],
        siteUID,
        products
      );
    } else {
      this.productGroupsSlice = {};
    }

    this.productsSlice = frontPage.products[0]
      ? FrontPage.parseSlice(frontPage.products[0], siteUID, products)
      : null;

    this.slices = frontPage.slices?.map((slice) =>
      FrontPage.parseSlice(slice, siteUID, products)
    );

    this.constructorName = 'FrontPage';
  }

  static parseSlice(slice, siteUID, products) {
    switch (slice._type) {
      case 'collectionSlice':
        return new Products(slice, siteUID, products);
      case 'heroSlice':
        return new Hero(slice, siteUID);
      case 'productGroupsSlice':
        return new ProductGroups(slice, siteUID);
      case 'productsSlice':
        return new Products(slice, siteUID, products);
      default:
        return new Slice(slice);
    }
  }
}

export default FrontPage;
